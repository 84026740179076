@import "~select2/dist/css/select2.css";
@import "metronic/line-awesome/css/line-awesome.css";
@import "metronic/toastr.css";
@import "metronic/flaticon/flaticon.css";
@import "metronic/flaticon2/flaticon.css";
@import "metronic/error-1.css";
@import "metronic/style.bundle.css";

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  font-family: “LineAwesome”;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  color: #A7ABC3;
  height: 38px;
  margin-right: 29px;
  font-size: 1.4rem;

}

.check-cols .form-check {
  display: inline-flex;
  margin-right: 2%;
  width: 31%;
}

.filter-date-range {
  & > .form-group {
    display: inline-table;
    margin-right: 2%;
    width: 48%;
  }

  .datepicker {
    width: 100%;
  }
}

.btn.btn-mini {
  padding: 2px 5px;
  line-height: 0.8em;

  .kt-nav__link-icon {
    font-size: 10px !important;
    line-height: 1em;
    padding: 0;
  }
}

.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px !important;
}

.btn-group .btn-active {
  background-color: #3d94fb;
  color: #ffffff;
}

.kt-badge.kt-badge--inline {
  height: auto;
  padding: 0.15rem 0.75rem;
}

.table thead th {
  white-space: nowrap;
  min-width: 65px;
}

/* .table.table-border-dark thead th {
  border-bottom: 1px solid #48494c;
}

.table-bordered.table-border-dark th, 
.table-bordered.table-border-dark td {
  border: 1px solid #48494c;
} */

.kt-datatable__row.collapsable {
  cursor: pointer;

  th { 
    position: relative;
  }

  th:after {
    position: absolute;
    font-size: 1rem;
    font-family: "LineAwesome";
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    content: "";
    right: 1rem;
    top: 10px;
  }

  &.collapsed th:after {
    color: #a7abc3;
    content: "";
  }
}

.table.table-ambitos .kt-datatable__row > .kt-datatable__cell {
  vertical-align: top !important;
}

.kt-datatable__cell .kt-badge.kt-badge--inline {
  display: inline-flex !important;
  height: auto;
  padding: 0.15rem 0.75rem;
}

.kt-datatable__cell .kt-badge {
  color: #fff !important;
}

.select2-container {
  width: 100% !important;
}

.col-form-label {
  padding: 0;
  margin-bottom: 0.5rem;
}

.form-group ~ .form-group {
  margin-top: -1.5rem;
}

.vich-image a img {
  height: auto;
  width: 100%;
}

.kt-widget7 .kt-widget7__desc {
  text-align: center;
  margin-top: 1rem;
  font-size: 1.3rem;
  color: #595d6e;
}

.kt-widget7 .kt-widget7__button {
  text-align: center;
  margin-top: 2rem;
}

.kt-widget16 {
  .kt-widget16__stats {
    padding-left: 0;
  }

  &.small {
    .kt-widget16__items .kt-widget16__item .kt-widget16__date {
      font-size: 10px;
    }

    .kt-badge.kt-badge--inline {
      padding: 0.5rem 1.5rem;
    }
  }
}

.kt-portlet .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title {
  padding: 0.25rem 0 0 !important
}

.kt-portlet .kt-portlet__body form hr {
  border-top: 1px dashed #ebedf2;
  margin-bottom: 2rem;
}

.kt-portlet .kt-portlet__body.ambitos {
  padding: 0;
}

.kt-portlet__foot .form-group {
  margin-bottom: 0;
}

.ambitos .kt-notification {
  .kt-notification__actions {
    align-items: center;
    display: flex;
  }

  .kt-notification__item {
    padding: 1.1rem 0.5rem;

    &.indicador:after {
      content: '';
    }

    &.active {
      text-decoration: none;
      background-color: rgba(29,201,183,.1);
    }

    & > a {
      flex-grow: 1;
    }
  }
}

.accordion {
  .card {
    overflow: visible !important;

    &:last-child {
      margin-bottom: 0;
    }

    .card-header {
      cursor: pointer;
      margin: 0;
      border-bottom: 0;
      padding: 0;
      background-color: #F3F6F9;

      .card-title {
        padding: 1rem 1.25rem;
        margin: 0;
        font-size: 1.15rem;
        font-weight: 500;
        color: #3699FF;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-transition: all 0.15s ease;
        transition: all 0.15s ease;

        > i {
          margin-right: 1rem;
          font-size: 1.4rem;
          color: #3699FF;
          -webkit-transition: all 0.15s ease;
          transition: all 0.15s ease;
        }

        .card-label {
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
        }

        &.collapsed {
          color: #3F4254;
          -webkit-transition: all 0.15s ease;
          transition: all 0.15s ease;

          > i {
            color: #B5B5C3;
            -webkit-transition: all 0.15s ease;
            transition: all 0.15s ease;
          }
        }

        &:after {
          right: 1rem;
        }
      }

      .card-text {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
      }
    }

    .card-body {
      padding: 1rem 1.25rem;
      font-size: 1rem;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.accordion-outline {
  .card {
    background-color: transparent;
  }
}

.accordion.accordion-light {
  .card {
    border: 0;
    border-top: 1px solid #EBEDF3;

    .card-header {
      background-color: transparent;
      border: 0;

      .card-title {
        padding: 1rem 0;

        &:after {
          right: 0;
        }
      }

      .card-body {
        padding: 0 0 1rem 0;
      }
    }

    &:first-child {
      border-top: 0;

      .card-header {
        margin: 0;
      }
    }

    &:last-child {
      .card-body {
        padding: 0;
        margin: 0;
      }
    }
  }
}

.accordion.accordion-light-borderless {
  .card {
    border-top: 0;
  }
}

.accordion.accordion-solid {
  .card {
    border: 0;
    margin-bottom: 1.25rem;

    .card-header {
      padding: 0;
      background-color: transparent;

      .card-title {
        background-color: #F3F6F9;
        border-radius: 0.42rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &.collapsed {
          border-radius: 0.42rem;
        }
      }
    }

    .card-body {
      border: 1px solid #F3F6F9;
      border-bottom-left-radius: 0.42rem;
      border-bottom-right-radius: 0.42rem;
    }

    &:last-child {
      margin: 0;
    }
  }
}

.accordion.accordion-toggle-plus {
  .card { 
    .card-header {
      .card-title {
        color: #3699FF;
        position: relative;

        &:after {
          -webkit-transition: all 0.15s ease;
          transition: all 0.15s ease;
          position: absolute;
          font-family: Ki;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          line-height: 1;
          text-decoration: inherit;
          text-rendering: optimizeLegibility;
          text-transform: none;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          font-smoothing: antialiased;
          content: "";
          font-size: 0.75rem;
          color: #3699FF;
        }

        &.collapsed {
          color: #3F4254;

          &:after {
            -webkit-transition: all 0.15s ease;
            transition: all 0.15s ease;
            color: #7E8299;
            font-family: Ki;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            line-height: 1;
            text-decoration: inherit;
            text-rendering: optimizeLegibility;
            text-transform: none;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            font-smoothing: antialiased;
            content: "";
          }
        }
      }
    }
  }
}

.accordion {
  &.accordion-toggle-arrow {
    .card {
      .card-header {
        .card-title {
          color: #3699FF;
          position: relative;

          &:after {
            position: absolute;
            font-family: Ki;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            line-height: 1;
            text-decoration: inherit;
            text-rendering: optimizeLegibility;
            text-transform: none;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            font-smoothing: antialiased;
            content: "";
            color: #3699FF;
            font-size: 0.7rem;
            -webkit-transition: all 0.15s ease;
            transition: all 0.15s ease;
          }

          &.collapsed {
            color: #3F4254;
            -webkit-transition: all 0.15s ease;
            transition: all 0.15s ease;

            &:after {
              color: #7E8299;
              -webkit-transition: all 0.15s ease;
              transition: all 0.15s ease;
              -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
              /*rtl:begin:*/
              /*rtl:end:*/
            }
          }
        }
      }
    }
  }

  &.accordion-svg-toggle {
    .card {
      .card-header {
        .card-title {
          .svg-icon {
            svg {
              -webkit-transition: all 0.15s ease;
              transition: all 0.15s ease;
              -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
              /*rtl:begin:*/
              /*rtl:end:*/

              height: 1.5rem;
              width: 1.5rem;
            }
          }

          &.collapsed {
            .svg-icon {
              svg {
                -webkit-transition: all 0.15s ease;
                transition: all 0.15s ease;
                -webkit-transform: rotate(0);
                transform: rotate(0);
              }
            }
          }
        }
      }
    }
  }

  &.accordion-panel {
    .card {
      border-radius: 0.42rem;

      .card-header {
        .card-title {
          background-color: #fff;
          border-radius: 0.42rem;
        }

        .card-body {
          padding-top: 0;
          border: 0;
          border-radius: 0.42rem;
        }
      }
    }
  }
}

span.checked {
  border-radius: 3px;
  background: none;
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;

  &:after {
    content: '';
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    margin-left: -2px;
    margin-top: -6px;
    width: 5px;
    height: 10px;
    border-width: 0 2px 2px 0/*rtl:ignore*/ !important;
    -webkit-transform: rotate(45deg)/*rtl:ignore*/;
    transform: rotate(45deg)/*rtl:ignore*/;
  }
}

.separator {
  height: 0;

  &.separator-dashed {
    border-bottom: 1px dashed #EBEDF3;
  }

  &.separator-solid {
    border-bottom: 1px solid #EBEDF3;
  }

  &.separator-border-2 {
    border-bottom-width: 2px;
  }

  &.separator-border-3 {
    border-bottom-width: 3px;
  }

  &.separator-border-4 {
    border-bottom-width: 4px;
  }

  &.separator-primary {
    border-bottom-color: #3699FF;
  }

  &.separator-secondary {
    border-bottom-color: #E4E6EF;
  }

  &.separator-success {
    border-bottom-color: #1BC5BD;
  }

  &.separator-info {
    border-bottom-color: #8950FC;
  }

  &.separator-warning {
    border-bottom-color: #FFA800;
  }

  &.separator-danger {
    border-bottom-color: #F64E60;
  }

  &.separator-light {
    border-bottom-color: #F3F6F9;
  }

  &.separator-dark {
    border-bottom-color: #181C32;
  }

  &.separator-white {
    border-bottom-color: #ffffff;
  }
}

.kt-list-timeline .kt-list-timeline__items .kt-list-timeline__item .kt-list-timeline__time {
  padding-right: 8px;
}

.sk-fading-circle {
  margin-left: 10px;
  width: 10px;
  height: 10px;
  position: relative;
  display: inline-block;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

.col-2dot4,
.col-sm-2dot4,
.col-md-2dot4,
.col-lg-2dot4,
.col-xl-2dot4 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-2dot4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.overflow-scroll-gradient {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    background: linear-gradient(rgba(255, 255, 255, 0.001), white); /* transparent keyword is broken in Safari */
    pointer-events: none;
  }
}

.overflow-scroll-gradient__scroller {
  overflow-y: scroll;
  background: white;
  padding: 0 0 30px 0;
  line-height: 1.2;
}

#myModalFilterResultados .modal-dialog.modal-xl {
  max-width: 90% !important;
}

.overlay {
  position: relative;

  &.overlay-block {
    cursor: wait;
  }

  &.overlay-block .overlay-layer, 
  &:hover .overlay-layer {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    opacity: 1;
  }

  .overlay-layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(0,0,0,.05);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    opacity: 0;
  }
}

.modal-open {
  overflow: hidden !important;
}

#myModalFilterResultados {
  .modal-body {
    height: 80vh;
    overflow: auto;
  }
}

.kt-user-card-v2__pic.logo img{
  border-radius: 0%;
  max-width: 100px;
}

.logos-footer {
  img {
    margin-right: 20px;
    margin-bottom: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
}

@media (min-width: 540px) {
  .col-sm-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 720px) {
  .col-md-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 960px) {
  .col-lg-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 1140px) {
  .col-xl-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.vabadus {
  font-family: 'Exo', sans-serif;
  font-weight: 700;
}

.filtro-resultados {
  display: none;
  background: #FFF;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  padding: 0 !important;

  .modal-header {
    align-items: center;
    padding: 30px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    background: #fff;

    h5 {
      margin-bottom: 0;
    }
  }

  .modal-body {
    padding: 130px 30px 98px;
  }

  .modal-footer {
    align-items: center;
    padding: 30px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
    background: #fff;
  }
}

.btn-close-filtro-resultados {
  font-size: 30px;
  color: #838489;
  font-weight: 600;
}

@media (max-width: 1024px) {
  .filtro-resultados {
    .modal-header {
      padding: 10px 30px;
      top: 60px;
      h5 {
        font-size: 14px;
      }
    }
    .modal-footer {
      padding: 15px 30px;
    }
  }
  
  .btn-close-filtro-resultados {
    font-size: 22px;
  }
}
